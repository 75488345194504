html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}
nav {
  width: 100%;
}

.logo1 {
  width: 4vw;
}

.right {
  position: absolute;
  right: 0;
}

.search {
  width: 2vw;
}

.nav-item {
  margin-left: 5vw;
}

/* .links:hover {
  color: gold;
} */

a:link {
  color: #1098ad;
  text-decoration: none;
  font-weight: 500;
}

a:visited {
  /* color: #777; */
  color: #1098ad;
}

a :hover {
  color: orangered;
  font-weight: bold;
  text-decoration: underline orangered;
}

a :active {
  background-color: black;
  font-style: italic;
}

.donate {
  background-color: goldenrod;
  border: 0;
  border-radius: 25px;
}

.donate:hover {
  background-color: black;
  color: white;
}

.subject {
  width: 50%;
  height: 60%;
}

.contact {
  margin-left: 30%;
  margin-top: 5%;
}

.fill {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

/* @media screen and (max-width: 600px) {
  .fill {
    width: 40%;
  } */

/* .fill {
    flex-wrap: wrap-reverse;
  } */

section h3 {
  font-weight: bold;
  margin-top: 5%;
}

input {
  width: 100%;
  padding: 1.4vw;
}

section button {
  margin-top: 6%;
  background: #1098ad;
  color: white;
  border: none;
  border-radius: 5px;
}

footer {
  background-color: #111;
  margin-top: 20%;
}

.footerContainer {
  width: 100%;
  padding: 70px 30px 20px;
}

.socialIcons {
  display: flex;
  justify-content: center;
}

.socialIcons a {
  padding: 10px;
  background-color: white;
  margin: 10px;
  border-radius: 50%;
}

.socialIcons a i {
  font-size: 2em;
  color: black;
  opacity: 0.9;
}

.socialIcons a :hover {
  color: #ff1c1c;
  transition: 0.5s;
}

.socialIcons a :hover i {
  color: #da2323 !important;
  transition: 0.5s;
}

.footerNav {
  margin: 30px 0;
}

.footerNav ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footerNav ul li a {
  color: white;
  margin: 10%;
  font-size: 1.3em;
  text-decoration: none;
  display: contents;
  font-weight: lighter;
  opacity: 0.7;
  transition: 0.5s;
}
.footerNav ul li a:hover {
  opacity: 1;
}

.footerBottom {
  background-color: #000;
  padding: 20px;
  text-align: center;
}

.footerBottom p {
  color: white;
}
