
html,
body {
  margin: 0;
  padding: 0;
  margin: 0 auto;
}
nav {
  width: 100%;
}

.logo1 {
  width: 4vw;
}

.right {
  position: absolute;
  right: 0;
}

.search {
  width: 2vw;
}

.nav-item {
  margin-left: 5vw;
}

a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 1.4vw;
}

.links:hover {
  color: gold;
}

.donate {
  background-color: goldenrod;
  border: 0;
  border-radius: 25px;
}

.donate:hover {
  background-color: black;
  color: white;
}

/* .container {
  position: relative;

  margin: 0;

  max-width: 100vw !important;
  padding: 0;
} */

/* .gradient {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(99, 9, 121, 21),
    rgba(0, 0, 255, 0.46)
  );
  position: absolute;
} */

/* img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

/* .step {
  box-shadow: 20 20px 30px 0 rgba(0, 0, 0.5);
}

.container h2 {
  position: absolute;
  top: 10%;
  margin: 26%;
  right: 3%;
  color: white;
} */

.container button {
  position: absolute;
  color: black;
  top: 85%;
  left: 32%;
  border: none;
  background-color: white;
  border-radius: 6px;
  padding: 1%;
  font-weight: bold;
}

.wrapper {
  position: relative;
  width: 400px;
  height: 440px;
  margin: 0 auto;
  margin-top: 10%;
  border: 2px solid rgba(0, 0, 0, 0.193);
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.079);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .form-box {
  width: 100%;
  padding: 40px;
}

.form-box h2 {
  font-size: 2em;
  color: #162938;
  text-align: center;
}

.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #162938;
  margin: 30px 0;
}

/* .input-box label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #162938;
  font-weight: 500;
  pointer-events: none;
  transition: -5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
  top: -5px;
}

.input-box input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1em;
  color: #162938;
  font-weight: 600;
  padding: 0 35px 0 5px;
}

.input-box .icon {
  position: absolute;
  right: 8px;
  font-size: 1.2em;
  color: #162938;
  line-height: 57px;
}*/

.remember-forgot {
  margin: -15px 0 15px;
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 100%;
  height: 45px;
  background: #162938;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  color: white;
  font-weight: 500;
}

/* .mama {
  width: 74%;
  height: 50vh;
} */

/* .common {
  display: flex;
  margin-top: 5%;
  margin: 10%;
} */

/* article h1 {
  text-align: center;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 8%;
}

.praise {
  width: 10vw;
  height: 20vh;
  border-radius: 50%;
  margin: 2%;
  margin-left: 40%;
}

@media screen and (max-width: 600px) {
  .step {
    width: 100%;
  }

  .common {
    flex-wrap: wrap-reverse;
  }

  img.mama {
    width: 90%;
  }
  .common p {
    font-size: 10px;
  }
  #tafos {
    width: 100%;
  }
  .middle h1 {
    font-size: 12px;
    margin-right: 8%;
  }
  .praise {
    width: 29%;
    position: absolute;
    right: 37%;
    height: 15dvh;
  }
  .gradient {
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(99, 9, 121, 21),
      rgba(0, 0, 255, 0.46)
    );
    position: absolute;
  } */

/* body {
  overflow-x: hidden;
}
.nav {
  width: 100%;
}
.container h2 {
  position: absolute;
  font-size: 15px;
}
.container button {
  font-size: 10px;
} */
.navbar-light .navbar-brand {
  font-size: 12px;
}

body {
  overflow-x: hidden;
}

.common p {
  font-size: 20px;
}

article h5 {
  margin-left: 38%;
}

/* .wrapper .input-box {
  margin: 5%;
} */

footer {
  background-color: #111;
  margin-top: 20%;
}

.footerContainer {
  width: 100%;
  padding: 70px 30px 20px;
}

.socialIcons {
  display: flex;
  justify-content: center;
}

.socialIcons a {
  padding: 10px;
  background-color: white;
  margin: 10px;
  border-radius: 50%;
}

.socialIcons a i {
  font-size: 2em;
  color: black;
  opacity: 0.9;
}

.socialIcons a :hover {
  color: #ff1c1c;
  transition: 0.5s;
}

.socialIcons a :hover i {
  color: #da2323 !important;
  transition: 0.5s;
}

.footerNav {
  margin: 30px 0;
}

.footerNav ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footerNav ul li a {
  color: white;
  margin: 10%;
  font-size: 1.3em;
  text-decoration: none;
  display: contents;
  font-weight: lighter;
  opacity: 0.7;
  transition: 0.5s;
}
.footerNav ul li a:hover {
  opacity: 1;
}

.footerBottom {
  background-color: #000;
  padding: 20px;
  text-align: center;
}

.footerBottom p {
  color: white;
}
