.checkout {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  color: black;
  margin-left: 15%;
}

.checkout h1{
  text-align: center;
  
}